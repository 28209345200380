import React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/core"
import { rhythm } from "../utils/typography"

import blogpostsStyles from "./blogposts.module.css"

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark (
          sort: {
            fields: [frontmatter___date]
            order: DESC
          }
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `
  );

  return (  
    <div className={blogpostsStyles.container}>
      <h1>
        Blog Posts
      </h1>
      <div className={blogpostsStyles.posts}>
        <h4 id={blogpostsStyles.postcounter}>{data.allMarkdownRemark.totalCount} Posts</h4>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id} className={blogpostsStyles.post}>
            <Link
              to={node.fields.slug}
              css={css`
                text-decoration: none;
                color: inherit;
              `}
            >
              <h3 css={css`
                  margin-top: ${rhythm(1 / 2)};
                `}
              >
                {node.frontmatter.title}{" "}
              </h3>
              <h3
                css={css`
                  margin-top: 0px;
                  margin-bottom: ${rhythm(1 / 3)};
                  color: #bbb;
                `}
              >
                {node.frontmatter.date}
              </h3>
              <p>{node.excerpt}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
