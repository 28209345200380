import React from "react"
import Layout from "../components/layout"
import Blogposts from "../components/blogposts"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="Blog" />
    <Blogposts />
  </Layout>
)